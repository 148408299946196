import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';
import bullet from '../../../static/img/check_bullet.svg';
import params from '../../utils/params';

const Section = styled.section`
  height: 710px;
  width: 100%;
  background: ${({background}) => background};

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    height: auto;
  }
`;

const Content = styled.div`
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 200px;

  .left {
    grid-column: 1;
  }

  .right {
    grid-column: 2;
  }

  h1 {
    margin: 1px 0 28px;
  }

  p {
    max-width: 650px;
  }

  ul {
    margin: 40px 0 44px;
  }

  li:before {
    content: url(${bullet});
    margin-right: 8px;
    margin-left: -24px;
    vertical-align: middle;
  }

  li {
    font-size: 18px;
    margin-top: 12px;
    margin-left: 24px;
  }

  a {
    background: ${theme.primaryLight};
    color: ${theme.textWhite};
    text-decoration: none;
    border-radius: 20px;
    padding: 8px 24px 12px;
    font-size: 16px;
  }
  a:hover {
    color: ${theme.textWhite};
  }

  a.gatsby-resp-image-link {
    padding: 0;
    background: none;
  }

  a.gatsby-resp-image-wrapper {
    padding: 0;
    background: none;
  }

  img {
    background: none;
  }

  @media screen and (${params.mediaQueryMaxWidth2000}) {
    max-width: 1100px;
  }

  @media screen and (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;
    grid-column-gap: 120px;
  }

  @media screen and (${params.mediaQueryMaxWidth1024}) {
    max-width: 700px;
    grid-column-gap: 80px;
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    max-width: 280px;
    padding: 38px 0 68px;
    grid-column-gap: 0;
    grid-template-rows: 2;
    grid-template-columns: 1fr;

    .left {
      grid-row: 1;
    }

    .right {
      grid-column: 1;
      grid-row: 2;
    }

    ul {
      margin-top: 24px;
    }
  }
`;

const Product = ({content, index}) => {
  const background = index % 2 === 0 ? theme.primaryBlue : theme.textWhite;
  const identifier = `product-${index}`;

  React.useEffect(() => {
    const wrapper = document.getElementById(identifier);
    const layoutConstructed = !!wrapper.querySelector('.left') && !!wrapper.querySelector('.right');
    if (!layoutConstructed) {
      const image = wrapper.querySelector('a.gatsby-resp-image-link');
      const imagePosition = index % 2 === 0 || window.innerWidth < 768 ? 'left' : 'right';
      const imageColumn = document.createElement('div');
      imageColumn.appendChild(image);
      imageColumn.classList.add(imagePosition);
      const textNodes = Array.from(wrapper.children).slice(1);
      const textColumn = document.createElement('div');
      textNodes.forEach(node => textColumn.appendChild(node));
      textColumn.classList.add(imagePosition === 'left' ? 'right' : 'left');
      Array.from(wrapper.children).forEach(child => wrapper.removeChild(child));
      wrapper.appendChild(textColumn);
      if (imagePosition === 'left') {
        wrapper.insertBefore(imageColumn, textColumn);
      } else {
        wrapper.appendChild(imageColumn);
      }
    }
  });

  return (
    <Section background={background}>
      <Content dangerouslySetInnerHTML={{__html: content}} id={identifier} />
    </Section>
  );
};

export default Product;
