import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../Components/layout';
import Hero from '../Components/main/hero';
import Faq from '../Components/main/faq';
import SalesPoints from '../Components/main/salesPoints';
import Product from '../Components/main/product';
import Demo from '../Components/main/demo';
import {filterByTypeSortByOrder} from '../utils/utils';

const IndexPage = ({
  data: {
    allMarkdownRemark: {edges},
  },
}) => {
  const products = filterByTypeSortByOrder(edges, 'product');
  const faqs = filterByTypeSortByOrder(edges, 'faq');

  return (
    <Layout withPadding={false}>
      <Hero />
      <SalesPoints />
      {products.map((product, idx) => (
        <Product key={idx} index={idx} content={product.node.html} />
      ))}
      <Demo />
      <Faq content={faqs[0].node.html} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "main"}}}) {
      edges {
        node {
          html
          frontmatter {
            type
            id
            order
          }
        }
      }
    }
  }
`;
