import React from 'react';
import styled from 'styled-components';
import theme from '../utils/theme';

const Container = styled.div`
  margin: ${({margins}) => (margins ? '30px 80px 100px' : 0)};
  width: 100%;

  pre {
    display: inline-block;
    line-height: 24px;
    overflow: auto;
    width: ${({width}) => width || '590px'};
    height: ${({height}) => height || 'auto'};
    background: #37404b;
    color: ${theme.textWhite};
    border-radius: 0 3px 3px 3px;
    box-shadow: 0 21px 30px -10px rgba(38, 38, 44, 0.4);
    padding: 0 18px 0 0;
    margin: 0;
    font-size: 14px;
  }
  code {
    display: inline-block;
    counter-increment: line;
    line-height: 24px;
    :before {
      display: inline-block;
      text-align: right;
      padding-right: 10px;
      margin-right: 18px;
      width: 30px;
      color: rgba(255, 255, 255, 0.4);
      background: rgba(75, 83, 93, 1);
      content: counter(line);
      font-size: 14px;
    }
  }
  code:first-child:before {
    padding-top: 18px;
  }
  code:last-child:before {
    padding-bottom: 20px;
  }

  a,
  .gatsby-resp-image-link {
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
  }

  a:hover,
  .gatsby-resp-image-link:hover {
    background: transparent !important;
    border: none !important;
  }
  a:active {
    border: 0 !important;
  }

  p,
  .gatsby-resp-image-link,
  .gatsby-resp-image-wrapper {
    margin: 0 !important;
    max-height: ${({height}) => height || '100%'};
    width: ${({width}) => width || '590px'} !important;
    overflow: hidden;
  }
  .gatsby-resp-image-wrapper {
    box-shadow: 0 21px 30px -12px rgba(38, 38, 44, 0.4);
  }

  img {
    object-fit: cover;
  }
`;
const Tab = styled.button`
  display: inline-block;
  padding: 10px 55px;
  margin-right: 3px;
  border: none;
  font-family: inherit;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
  background: ${({active}) => (active ? theme.primaryLight : 'rgba(64, 177, 109, 0.1)')};
  color: ${({active}) => (active ? theme.textWhite : theme.textBlack)};
  cursor: pointer;
  transition: 100ms;

  &:hover {
    background: ${theme.primaryLight};
    color: ${theme.textWhite};
  }
  &:focus {
    outline: none;
  }
`;

const Tabs = ({tabs, options = {}}) => {
  const [activeTab, setActiveTab] = React.useState(null);
  const handleClick = tabName => setActiveTab(tabName);
  let codeHtml = '';
  React.useEffect(() => {
    if (activeTab === null && tabs.length > 0) {
      setActiveTab(tabs[0].name);
    }
  });
  if (activeTab) {
    const currentTab = tabs.find(tab => tab.name === activeTab);
    if (currentTab.type === 'image') {
      codeHtml = currentTab.element.innerHTML;
    } else {
      const codeRows = currentTab.element.innerHTML.trim().split(/\n/); //Split and join to add line numbering
      codeHtml = codeRows[0].concat(codeRows.slice(1).join('</code>\n<code>')).concat('</code>');
    }
  }

  return (
    <Container {...options}>
      {tabs.map(({name}) => {
        return (
          <Tab active={name === activeTab} onClick={() => handleClick(name)} key={name}>
            {name}
          </Tab>
        );
      })}
      {!!activeTab && <div dangerouslySetInnerHTML={{__html: codeHtml}} />}
    </Container>
  );
};

Tabs.defaultProps = {
  tabs: [],
};

export default Tabs;
