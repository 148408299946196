import React from 'react';
import styled from 'styled-components';
import DomParser from 'dom-parser';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import Hero from '../hero';
import theme from '../../utils/theme';
import chevronDown from '../../../static/img/chevron_dropdown.svg';
import Tabs from '../tabs';
import params from '../../utils/params';
import {getPortalUrls} from '../../utils/api';

const Container = styled.div`
  p {
    margin-bottom: 40px;
  }

  a {
    color: ${theme.textBlack};
    border-color: ${theme.primaryLight};
    border-style: solid;
    border-width: 1px;
    background: transparent;
    text-decoration: none;
    border-radius: 20px;
    padding: 8px 24px 12px;
    font-size: 16px;
  }

  a:hover {
    background: ${theme.primaryLight};
    color: ${theme.textWhite};
  }

  a:active {
    background: ${theme.primaryDark};
    color: ${theme.textWhite};
    border-color: ${theme.primaryDark};
  }

  section {
    height: 681px;
  }

  .gatsby-image-wrapper {
    height: 390px !important;

    @media screen and (${params.mediaQueryMaxWidthLarge}) {
      height: 150px !important;
    }
  }

  .hero_section > div {
    @media screen and (${params.mediaQueryMaxWidth2000}) {
      max-width: 1100px;
    }

    @media screen and (${params.mediaQueryMaxWidth1440}) {
      max-width: 900px;
    }

    @media screen and (${params.mediaQueryMaxWidth1024}) {
      max-width: 700px;
    }

    @media screen and (${params.mediaQueryMaxWidthLarge}) {
      max-width: 280px;
    }
  }

  @media screen and (${params.mediaQueryMaxWidth2000}) {
    h1,
    p {
      max-width: 350px;
    }
  }

  @media screen and (${params.mediaQueryMaxWidth1440}) {
    h1,
    p {
      max-width: 280px;
    }
  }

  @media screen and (${params.mediaQueryMaxWidth1024}) {
    h1 {
      margin-top: 520px;
    }

    h1,
    p {
      max-width: 520px;
    }

    section {
      padding-bottom: 30px;
      height: auto;
    }
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    h1 {
      margin-top: 0;
    }

    h1,
    p {
      max-width: 280px;
    }

    section {
      padding: 50px 0 40px;
    }
  }
`;

const Section = styled.section`
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 !important;
  height: auto !important;

  @media screen and (${params.mediaQueryMaxWidth2000}) {
    max-width: 1100px;
  }

  @media screen and (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;
  }

  @media screen and (${params.mediaQueryMaxWidth1024}) {
    max-width: 700px;
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    max-width: 280px;
  }
`;

const MainContent = styled.div`
  height: 715px;
  width: 986px;
  background: ${theme.textWhite};
  position: absolute;
  top: -170px;
  right: -32px;
  box-shadow: 0 42px 60px -20px rgba(38, 38, 44, 0.2);
  border-radius: 6px;
  padding: 30px;

  p {
    max-width: 100%;
  }

  @media screen and (${params.mediaQueryMaxWidth2000}) {
    width: 694px;
    right: -52px;
  }

  @media screen and (${params.mediaQueryMaxWidth1440}) {
    width: 575px;
  }

  @media screen and (${params.mediaQueryMaxWidth1024}) {
    top: -150px;
    right: auto;
    left: calc(50% - 286px);
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    display: none;
  }
`;

const Label = styled.label`
  display: inline-block;
  margin: 28px 0 58px;
  font-size: 16px;
  position: relative;
`;

const Dropdown = styled(Label)`
  &:after {
    position: absolute;
    left: 310px;
    top: 40px;
    height: 30px;
    width: 24px;
    content: url(${chevronDown});
    pointer-events: none;
  }
`;

const Select = styled.select`
  display: block;
  margin-top: 8px;
  width: 340px;
  padding: 8px 13px 12px;
  border: 1px solid rgba(155, 189, 202, 0.5);
  border-radius: 3px;
  outline: none;
  background: transparent;
  appearance: none;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 29px;
  color: rgba(38, 38, 44, 0.6);
  cursor: pointer;
  transition: 100ms;

  &:hover {
    border: 1px solid rgba(155, 189, 202, 1);
  }
  &:active {
    border: 1px solid rgba(38, 38, 44, 1);
  }
`;

const Demo = () => {
  const [config, setConfig] = React.useState(null);
  const {
    background,
    data: {edges},
  } = useStaticQuery(graphql`
    query {
      background: file(relativePath: {eq: "demo_hero.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2000, maxHeight: 390, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      data: allMarkdownRemark(filter: {frontmatter: {id: {eq: "main-demo"}}}) {
        edges {
          node {
            html
            frontmatter {
              type
              title
            }
          }
        }
      }
    }
  `);
  const identifier = 'demoSectionHero';
  const htmlParser = new DomParser();
  const heroContent = edges.find(edge => edge.node.frontmatter.type === 'hero').node.html;
  const demoNode = edges.find(edge => edge.node.frontmatter.type === 'content').node;
  const parsedHtml = htmlParser.parseFromString(demoNode.html);
  const examples = parsedHtml.getElementsByTagName('example');
  const exampleNames = examples.map(example => example.getAttribute('name'));
  const [example, setExample] = React.useState(null);
  const handleExampleChange = event => setExample(event.target.value);
  if (!example && exampleNames.length > 0) {
    setExample(exampleNames[0]);
  }
  const tabs = examples.reduce((arr, node) => {
    node.childNodes.forEach(child => {
      arr.push({
        element: child,
        name: child.getAttribute('name'),
        example: node.getAttribute('name'),
        type: child.getAttribute('type') || 'code',
      });
    });

    return arr;
  }, []);

  React.useEffect(() => {
    if (!config) {
      getPortalUrls().then(response => setConfig(response));
    }
    const link = document.getElementById(identifier).getElementsByTagName('a')[0];
    const newLink = document.createElement('a');
    newLink.innerHTML = link.innerHTML;
    newLink.setAttribute('href', config ? config.API_DOCS_URL : '/');
    link.parentNode.replaceChild(newLink, link);
  });

  return (
    <Container>
      <Img
        style={{
          margin: '0 auto',
          height: '100%',
        }}
        alt="Aerial view of an old town"
        fluid={background.childImageSharp.fluid}
      />
      <Section>
        <MainContent>
          <h2>{demoNode.frontmatter.title}</h2>
          <Dropdown htmlFor="examples">
            Choose address
            <Select id="examples" onChange={handleExampleChange}>
              {exampleNames.map(name => (
                <option key={name}>{name}</option>
              ))}
            </Select>
          </Dropdown>
          <Tabs
            options={{margins: false, height: '415px', width: '100%'}}
            tabs={tabs.filter(tab => tab.example === example && !!tab.name)}
          />
        </MainContent>
      </Section>
      <Hero options={{padding: '80px 50px', id: identifier}} content={heroContent} />
    </Container>
  );
};

export default Demo;
