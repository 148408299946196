import React from 'react';
import styled from 'styled-components';
import params from '../../utils/params';
import data from '../../../static/img/sales-points/up-to-date.svg';
import integration from '../../../static/img/sales-points/easy-intergration.svg';
import reporting from '../../../static/img/sales-points/built-in-reporting.svg';
import support from '../../../static/img/sales-points/support.svg';

const points = [
  {
    src: data,
    text: 'Most up-to-date regional data',
  },
  {
    src: integration,
    text: 'Easy integration',
  },
  {
    src: reporting,
    text: 'Built-in reporting tool',
  },
  {
    src: support,
    text: '24/7 technical support',
  },
];

const Section = styled.section`
  max-width: 1600px;
  margin: 0 auto;
  ul,
  li {
    display: flex;
  }

  ul {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  li {
    margin: 0;
    flex-direction: column;
    align-items: space-between;
  }

  img {
    max-height: 50px;
    margin-top: 94px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin: 32px 0 66px;
    text-align: center;
  }

  @media screen and (${params.mediaQueryMaxWidth2000}) {
    max-width: 1100px;
  }

  @media screen and (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;

    li {
      max-width: 200px;
    }
  }

  @media screen and (${params.mediaQueryMaxWidth1024}) {
    max-width: 700px;

    li {
      max-width: 152px;
    }
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    max-width: 280px;

    li {
      max-width: 134px;
    }

    img {
      margin-top: 68px;
    }

    li:nth-child(-n + 3) p {
      margin-bottom: 0;
    }
  }
`;

const SalesPoints = () => {
  return (
    <Section>
      <ul>
        {points.map(({src, text}) => (
          <li key={text}>
            <img alt={text} src={src} />
            <p>{text}</p>
          </li>
        ))}
      </ul>
    </Section>
  );
};

export default SalesPoints;
