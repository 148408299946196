import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';
import triangle from '../../../static/img/triangle_round_corners_large.svg';
import params from '../../utils/params';

const Section = styled.section`
  height: 710px;
  max-width: 1600px;
  margin: 0 auto;
  background: ${theme.textWhite};

  @media screen and (${params.mediaQueryMaxWidth2000}) {
    max-width: 1100px;
  }

  @media screen and (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;
  }

  @media screen and (${params.mediaQueryMaxWidth1024}) {
    max-width: 700px;
    height: auto;
    padding: 50px 0 64px;
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    max-width: 280px;
  }
`;

const Content = styled.div`
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 200px;

  .left {
    grid-column: 1;
  }
  .right {
    grid-column: 2;
  }
  h1 {
    margin: 1px 0 28px;
  }
  p {
    max-width: 650px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  ul {
    margin: 40px 0 44px;
  }
  li:before {
    content: url(${triangle});
    margin: 0 12px 0 -22px;
  }
  li {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 18px 22px;
  }

  a {
    color: ${theme.textBlack};
    border: 1px solid ${theme.primaryLight};
    background: ${theme.textWhite};
    text-decoration: none;
    border-radius: 20px;
    padding: 8px 24px 12px;
    font-size: 16px;
  }

  a:hover {
    background: ${theme.primaryLight};
    color: ${theme.textWhite};
  }

  a:active {
    background: ${theme.primaryDark};
    color: ${theme.textWhite};
    border-color: ${theme.primaryDark};
  }

  @media screen and (${params.mediaQueryMaxWidth1440}) {
    grid-column-gap: 120px;
  }

  @media screen and (${params.mediaQueryMaxWidth1024}) {
    grid-template-columns: 520px;

    .right {
      display: none;
    }
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    grid-template-columns: 1fr;
    grid-template-rows: 2;

    .left {
      grid-row: 1;
    }

    .right {
      grid-column: 1;
      grid-row: 2;
      display: inherit;
      justify-items: start;
    }

    ul,
    .left p {
      margin: 0 0 20px;
    }

    li {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 18px;
    }
  }
`;

const Faq = ({content}) => {
  const id = 'mainFaq';

  React.useEffect(() => {
    const wrapper = document.getElementById(id);
    const layoutConstructed = !!wrapper.querySelector('.left') && !!wrapper.querySelector('.right');
    if (!layoutConstructed) {
      const textNodes = Array.from(wrapper.children).slice(0);
      const textColumn = document.createElement('div');
      const listColumn = document.createElement('div');
      const listNode = wrapper.querySelector('ul');
      textNodes.forEach(node => textColumn.appendChild(node));
      textColumn.classList.add('left');
      listColumn.appendChild(listNode);
      listColumn.classList.add('right');
      if (window.innerWidth < 768) {
        const link = textColumn.querySelector('a');
        listColumn.appendChild(link);
      }
      Array.from(wrapper.children).forEach(child => wrapper.removeChild(child));
      wrapper.appendChild(textColumn);
      wrapper.appendChild(listColumn);
    }
  });

  return (
    <Section>
      <Content dangerouslySetInnerHTML={{__html: content}} id={id} />
    </Section>
  );
};

export default Faq;
