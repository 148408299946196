import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import theme from '../../utils/theme';
import params from '../../utils/params';

const Section = styled.section`
  width: 100%;
  height: 813px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h4,
  h1,
  p {
    z-index: 2;
  }

  h1,
  p {
    color: ${theme.textWhite};
  }

  @media screen and (${params.mediaQueryMaxWidth1440}) {
    height: 680px;
  }

  @media screen and (${params.mediaQueryMaxWidth1024}) {
    height: 968px;
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    height: 460px;

    h4 {
      margin-bottom: 12px;
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  max-width: 4000px;
  position: absolute;
  top: 0;
  left: auto;
  background-color: ${theme.textBlack};
  opacity: 0.5;
`;

const Lead = styled.p`
  position: absolute;
  bottom: 123px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 230px;
  height: 54px;
  padding: 10px;
  border-radius: 27px;
  text-align: center;
  border: 2px solid ${theme.primaryLight};

  &:after {
    content: '';
    position: absolute;
    height: 238px;
    width: 2px;
    background: linear-gradient(to bottom, ${theme.primaryLight} 30%, ${theme.textWhite} 100%);
    top: 50px;
    left: 50%;
  }

  @media screen and (${params.mediaQueryMaxWidthLarge}) {
    bottom: 48px;

    &:after {
      height: 90px;
    }
  }
`;

const Hero = () => {
  const {heroBg} = useStaticQuery(graphql`
    query {
      heroBg: file(relativePath: {eq: "landing_hero.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Section>
      <Background>
        <Img
          style={{
            height: '100%',
            margin: '0 auto',
          }}
          alt="A forest with a winding road"
          fluid={heroBg.childImageSharp.fluid}
        />
      </Background>
      <Overlay />
      <h4>Everything in one place</h4>
      <h1>Your source of Geoservices</h1>
      <Lead>Explore our products</Lead>
    </Section>
  );
};

export default Hero;
